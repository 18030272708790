import { React, useEffect, useState } from "react";
import Product from "./Product";
import styles from "../styles/components/Tile.module.sass";
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";

function Tile(props) {
  const [products, setProducts] = useState([]);

  async function fetchProducts() {
    const q = query(
      collection(db, "products"),
      where("category", "==", props.slug),
      orderBy("votes", "desc"),
      limit(3)
    );
    const querySnapshot = await getDocs(q);
    const prod = [];
    querySnapshot.forEach((doc) => {
      prod.push(doc.data());
    });
    setProducts(prod);
  }
  useEffect(() => {
    fetchProducts();
  }, []);

  const category = props.name;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.category}>{category}</h3>
        <a href="#" className={styles.see_more}>
          See more
        </a>
      </div>
      <ul>
        {products &&
          products.map((prod) => (
            <li className={styles.product}>
              <Product key={prod.id} data={prod} />
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Tile;
