import { React, useEffect, useState } from "react";
import Tile from "./Tile";
import styles from "../styles/components/MainGrid.module.sass";

import { db } from "../firebase";
import { collection, getDocs, query } from "firebase/firestore";

function MainGrid() {
  const [categories, setCategories] = useState([]);

  async function fetchCategories() {
    const query_ = query(collection(db, "categories"));
    const snapshot = await getDocs(query_);
    const cats = [];
    snapshot.forEach((cat) => {
      cats.push(cat.data());
    });
    setCategories(cats);
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={styles.container}>
      {categories &&
        categories.map((cat) => (
          <Tile
            key={cat.id}
            slug={cat.slug}
            className={styles.tile}
            name={cat.pluriel}
          />
        ))}
    </div>
  );
}

export default MainGrid;
