import React from "react";
import Hero from "./Hero";
import MainGrid from "./MainGrid";

function Landing() {
  return (
    <>
      <Hero />
      <MainGrid />
    </>
  );
}

export default Landing;
