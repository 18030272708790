import React, { useState } from "react";
import styles from "../styles/components/Register.module.sass";

import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import slugify from "react-slugify";

function AddProduct() {
  const productsRef = collection(db, "products");
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [category, setCategory] = useState("");
  const [img, setImg] = useState(
    "https://ssr-releases-cdn.paperlesspost.com/_next/static/images/MobileMediaPoster-553a691ac40df070a04c82b601a117ec.jpg"
  );
  const votes = 0;

  const updateWebsite = (e) => {
    setWebsite(e.target.value);
    setImg("https://icon.horse/icon/" + e.target.value);
  };

  const submit = async (e) => {
    const slug = slugify(name);
    e.preventDefault();

    await addDoc(productsRef, {
      name: name,
      slug: slug,
      website: website,
      category: category,
      votes: votes,
      tagline: tagline,
    });
    alert("Product Created :D");
  };

  return (
    <div className={styles.register}>
      <form className={styles.register__container} onSubmit={submit}>
        <input
          type="text"
          className={styles.register__textBox}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Product name"
        />
        <input
          type="url"
          className={styles.register__textBox}
          value={website}
          onChange={(e) => updateWebsite(e)}
          placeholder="Website"
        />
        <input
          type="text"
          className={styles.register__textBox}
          value={tagline}
          onChange={(e) => setTagline(e.target.value)}
          placeholder="Tagline"
        />
        <input
          type="text"
          className={styles.register__textBox}
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Category"
        />
        <img src={img} alt="" height="100px" width="100px" />
        <button className={styles.register__btn} type="submit">
          Submit
        </button>
        <div></div>
      </form>
    </div>
  );
}

export default AddProduct;
