import { Component, React } from "react";
import styles from "../styles/components/Product.module.sass";

function Product(props) {
  const data = props.data;
  const img_url = "https://icon.horse/icon/" + data.website;

  return (
    <div className={styles.container}>
      <div className={styles.product_content}>
        <img className={styles.logo} src={img_url} alt="" />
        <div>
          <h4 className={styles.product_name}>{data.name}</h4>
          <div className={styles.famous_votes}>
            <span>Famous hunters Placeholder</span>
          </div>
        </div>
      </div>
      <div className={styles.upvote}>
        <span>{data.votes}</span>
        <svg
          fill="none"
          viewBox="0 0 23 23"
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="20"
        >
          <path
            d="m11.5 0.5625c-6.04 0-10.938 4.8975-10.938 10.938 0 6.04 4.8975 10.938 10.938 10.938 6.04 0 10.938-4.8975 10.938-10.938 0-6.04-4.8975-10.938-10.938-10.938zm4.3457 13.55h-1.145c-0.249 0-0.4859-0.1196-0.6323-0.3223l-2.5684-3.5498-2.5684 3.5498c-0.14648 0.2027-0.38086 0.3223-0.63232 0.3223h-1.145c-0.15869 0-0.25147-0.1807-0.15869-0.3101l4.3457-6.0058c0.0781-0.10743 0.2368-0.10743 0.315 0l4.3457 6.0058c0.0952 0.1294 0.0024 0.3101-0.1563 0.3101z"
            fill="#3A86FF"
          />
        </svg>
      </div>
    </div>
  );
}

export default Product;
