import { Component, React } from "react";
import styles from "../styles/components/NavBar.module.sass";
import { Link } from "react-router-dom";
import { auth, logout } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function NavBar() {
  const [user, loading, error] = useAuthState(auth);

  const authButton = () => {
    if (user) {
      return (
        <>
          <a href="#" className={styles.navlink}>
            <span className={styles.navspan} onClick={logout}>
              Log out
            </span>
          </a>
          <Link to="/dashboard">
            <img
              src={
                "https://avatars.dicebear.com/api/avataaars/" +
                user.uid +
                ".svg"
              }
              alt={user.name}
              className={styles.avatar}
            />
          </Link>
        </>
      );
    } else {
      return (
        <Link to="/login" className={styles.navlink}>
          <span className={styles.navspan}>Log in</span>
        </Link>
      );
    }
  };
  return (
    <nav className={styles.container}>
      <div className={styles.navlinks}>
        <a href="#" className={styles.navlink}>
          <span className={styles.navspan}>Trending</span>
        </a>
        <a href="#" className={styles.navlink}>
          <span className={styles.navspan}>Categories</span>
        </a>
      </div>
      <Link to="/">
        <h2 className={styles.title}>Get Productive</h2>
      </Link>
      <div className={styles.navlinks}>
        <a href="#" className={styles.navlink}>
          <span className={styles.navspan}>About</span>
        </a>
        {authButton()}
      </div>
    </nav>
  );
}

export default NavBar;
