import { Component, React } from "react";
import styles from "../styles/components/SearchBar.module.sass";

class SearchBar extends Component {
  render() {
    return (
      <form className={styles.search_bar}>
        <input
          type="search"
          placeholder="Note taking app, transcript tool, meetings recorder ..."
          className={styles.search_input}
        />
        <svg
          fill="none"
          className={styles.search_icon}
          viewBox="0 0 35 35"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m17.5 0c-9.665 0-17.5 7.835-17.5 17.5 0 9.665 7.835 17.5 17.5 17.5 9.665 0 17.5-7.835 17.5-17.5 0-9.665-7.835-17.5-17.5-17.5zm1.2006 7.5836c4.6822 0 8.4765 3.7965 8.4765 8.4787s-3.7943 8.4766-8.4765 8.4766c-1.417 0-2.7524-0.3467-3.9264-0.9613 0.0012 0.0054 0.0031 0.0117 0.0042 0.0171l-3.8238 3.8217-3.1317-3.1339 3.6871-3.6892c0.0061-0.0028 0.0131-0.0038 0.0192-0.0064-0.8279-1.3094-1.3073-2.8609-1.3073-4.5246 0-4.6822 3.7964-8.4787 8.4787-8.4787zm0 3.7662c-2.6021 0-4.7126 2.1105-4.7126 4.7125 0 2.6021 2.1105 4.7104 4.7126 4.7104 2.602 0 4.7103-2.1083 4.7103-4.7104 0-2.602-2.1083-4.7125-4.7103-4.7125z"
            fill="#3A86FF"
          />
        </svg>
      </form>
    );
  }
}

export default SearchBar;
