import { Component, React } from "react";
import styles from "../styles/components/Hero.module.sass";
import SearchBar from "./SearchBar";

class Hero extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>
          9875 persons have helped rank the{" "}
          <span className={styles.highlight}>best productivity tools</span>.{" "}
          <br />
          Be the 9876th !
        </h1>
        <SearchBar className={styles.search_bar} />
      </div>
    );
  }
}

export default Hero;
